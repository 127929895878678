import React from 'react';
import Styles from './styles.module.scss';
import Image from '../../gatsby-image';

const MissionCard = ({ image }) => (
  <div className={Styles.milestoneCard}>
    <div className={Styles.milestoneCardImg}>
      <Image filename={image} />
    </div>
  </div>
);

export default MissionCard;
