import React from 'react';

import Styles from './styles.module.scss';
import Image from '../../gatsby-image';

const ClientCard = ({ img }) => (
  <div className={Styles.clientCard}>
    <div className={Styles.clientCardImg}>
      <Image filename={img} alt="" />
    </div>
  </div>
);
export default ClientCard;
