/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
// import { Link } from 'gatsby';
import Slider from 'react-slick';

import { Carousal } from '../../components/landing';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import BannerSection from '../../components/banner';

import landingBanner from '../../../static/banners/partners.svg';
import grow from '../../../static/partnership/png/grow.svg';
import Styles from '../styles/landingPage.module.scss';
import partnersStyles from '../styles/partners.module.scss';
import '../../common-styles/styles.css';

import { benefitsList } from '../../mockdata/benefits';
import { clientList } from '../../mockdata/clients';
import { growclientList } from '../../mockdata/growclients';

import PartnersCard from '../../components/partnership/partnersCard/partnersCard';
import ClientCard from '../../components/partnership/clientCard/clientCard';
import MissionCard from '../../components/partnership/missionCard/missionCard';
import arrowLeft from '../../../static/home/svg/Arrowleft.svg';
import arrowRight from '../../../static/home/svg/Arrowright.svg';
import missionImages from '../../mockdata/teamActivity';
import { saveURLParamToSession } from '../../utils';

const Partnership = () => {
  useEffect(() => {
    const hashValue = window
      && window.location
      && window.location.hash
      && window.location.hash.slice(1);
    if (hashValue) {
      const section = window && window.document && window.document.getElementById(hashValue);
      const headerOffset = 30;
      const elementPosition = section && section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition
      });
    }
  }, []);

  const handleKnowMoreClick = () => {
    const section = window && window.document && window.document.getElementById('section2');
    const headerOffset = 30;
    const elementPosition = section && section.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
    prevArrow: <img src={arrowLeft} alt="grow" />,
    nextArrow: <img src={arrowRight} alt="grow" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrow: true,
          prevArrow: <img src={arrowLeft} alt="grow" />,
          nextArrow: <img src={arrowRight} alt="grow" />
        }
      }
    ]
  };

  const partnerSlider = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: true,
    arrow: true,
    variableWidth: true,
    prevArrow: <img src={arrowLeft} alt="grow" />,
    nextArrow: <img src={arrowRight} alt="grow" />
  };

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  return (
    <Layout>
      <SEO
        title="Capital as a service"
        description="Klub partners with financial institutions to provide growth capital for local economies."
      />
      {/* Landing tile */}
      <BannerSection
        image={landingBanner}
        heading="Capital as a Service"
        // subHeading="for Loved Brands"
        description="Driving growth for local economies"
        buttons={[
          <button
            onClick={handleKnowMoreClick}
            type="button"
            className="btn btnklub1"
          >
            Know More
          </button>
        ]}
      />

      {/* Partnership Card section */}
      <div className={partnersStyles.benefitCardsSection} id="section2">
        <div className={Styles.homeSection6}>
          <div className="container">
            <div className={Styles.sectionContentBox}>
              <h3 className={` ${Styles.dlgblock}`}>FOR CAPITAL PARTNERS</h3>
              <h1 className={` ${Styles.dlgblock}`}>
                Superior returns with diversification benefits
              </h1>
              <ul className={partnersStyles.listStyle}>
                <li>
                  Great businesses require multiple sources of financing to
                  match their growth needs. Our platform approach to financing
                  helps founders unlock multiple complementary sources of
                  capital.
                </li>
              </ul>
            </div>
          </div>
          <div className={Styles.klubNews}>
            <Carousal
              margin={true}
              autoScroll={false}
              name="testimonials-about"
            >
              {benefitsList.map((item) => (
                <PartnersCard
                  key={item.id}
                  img={item.imgSrc}
                  head={item.head}
                  desc={item.desc}
                />
              ))}
            </Carousal>
          </div>
          <div
            className={`${Styles.sectionButtons} ${Styles.mt4} ${partnersStyles.buttonCenter}`}
          >
            <a
              href="mailto:contact@klubworks.com"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btnklub3"
            >
              Explore synergies
            </a>
          </div>
        </div>
      </div>

      {/* Clients */}
      <div className={Styles.homeSection6}>
        <div className="container">
          <div className={Styles.sectionContentBox}>
            <h3 className={` ${Styles.dlgblock}`}>
              DATA + HEURISTICS: BEST OF BOTH WORLDS
            </h3>
            <h5 className={` ${Styles.dlgblock}`}>
              Data integrations that we support
            </h5>
          </div>
        </div>
        <div className={`${Styles.klubNews} klubnewsSlider`}>
          <Slider {...partnerSlider}>
            {clientList.map((item) => (
              <ClientCard key={item.id} img={item.imgSrc} />
            ))}
          </Slider>
        </div>
      </div>

      <div className={`${Styles.sectionWrapper} ${Styles.dlgblock}`}>
        <div className={Styles.homeSection6}>
          <div className="container">
            <div className={Styles.row}>
              <div className={Styles.subSectionStyle}>
                <div className={partnersStyles.mobHide}>
                  <div className={partnersStyles.growImg}>
                    <div>
                      <img src={grow} alt="grow" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={partnersStyles.growSection}>
                <div className={Styles.sectionContentBox}>
                  <h3 className={` ${Styles.dlgblock}`}>
                    GROW THE PIE TOGETHER
                  </h3>
                  <h5 className={` ${Styles.dlgblock}`}>
                    Focused on long-term partnerships, not on short-term
                    transactions
                  </h5>
                  <div className={partnersStyles.mobShow}>
                    <div className={partnersStyles.growImg}>
                      <div>
                        <img src={grow} loading="lazy" alt="grow" />
                      </div>
                    </div>
                  </div>

                  <ul className={partnersStyles.growStyle}>
                    <li>
                      We value strategic partnerships over do-it-all mindsets
                      and are committed to building great alliances. We are
                      extremely fortunate to work with multiple strategic
                      partners who share the same values.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={`${Styles.klubNews} klubnewsSlider`}>
            <Slider {...partnerSlider}>
              {growclientList.map((item) => (
                <ClientCard
                  key={item.id}
                  img={item.imgSrc}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* invest in brands on section desktop */}
      <div
        className={`${Styles.sectionWrapper}  ${Styles.dlgblock} ${partnersStyles.padBottom}`}
      >
        <div className={Styles.homeSection6}>
          <div className="container">
            <div className={Styles.row}>
              <div className={partnersStyles.missionSectionLeft}>
                <div className={Styles.sectionContentBox}>
                  <h3 className={` ${Styles.dlgblock}`}>
                    MISSION-DRIVEN CULTURE
                  </h3>
                  <h5 className={` ${Styles.dlgblock}`}>
                    Building for a deeper purpose
                  </h5>
                  <ul className={partnersStyles.growStyle}>
                    <li>
                      We believe in growing together with our partners,
                      investors, and team members. We have embraced this as our
                      cultural fabric promising to plant a tree for each of our
                      long-term partnerships.
                    </li>
                  </ul>
                </div>
              </div>
              <div className={partnersStyles.missionSectionRight}>
                <div className="missionSliderContainer">
                  <Slider {...settings}>
                    {missionImages.map((image, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MissionCard image={image.image} key={`index_${index}`} />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Partnership;
