import React from 'react';

import Styles from './styles.module.scss';

const PartnersCard = ({ img, head, desc }) => (
  <div className={Styles.PartnersCard}>
    <div className={Styles.PartnersCardImg}>
      <img src={img} alt="" />
    </div>
    <h2 className={Styles.PartnersCardHeading}>{head}</h2>
    <p className={Styles.PartnersCardDesc}>{desc}</p>
  </div>
);
export default PartnersCard;
