export const clientList = [
  {
    id: '1',
    imgSrc: 'partnership/amazon.png'
  },

  {
    id: '2',
    imgSrc: 'partnership/facebook.png'
  },

  {
    id: '3',
    imgSrc: 'partnership/google.png'
  },

  {
    id: '4',
    imgSrc: 'partnership/shopify.png'
  },

  {
    id: '5',
    imgSrc: 'partnership/razorpay.png'
  },

  {
    id: '6',
    imgSrc: 'partnership/cashfree.png'
  },

  {
    id: '7',
    imgSrc: 'partnership/limetray.png'
  },

  {
    id: '8',
    imgSrc: 'partnership/payu.png'
  },

  {
    id: '9',
    imgSrc: 'partnership/perfios.png'
  },

  {
    id: '10',
    imgSrc: 'partnership/probe.png'
  },

  {
    id: '11',
    imgSrc: 'partnership/unicommerce.png'
  }
];
