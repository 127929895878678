export const growclientList = [
  {
    id: '1',
    imgSrc: 'partnership/grow/meesho.png'
  },
  {
    id: '2',
    imgSrc: 'partnership/grow/enkash.png'
  },
  {
    id: '3',
    imgSrc: 'partnership/grow/inmobi.png'
  },
  {
    id: '4',
    imgSrc: 'partnership/grow/ezcred.png'
  },
  {
    id: '5',
    imgSrc: 'partnership/grow/adyogi.png'
  },
  {
    id: '6',
    imgSrc: 'partnership/grow/coffeemug.png'
  },
  {
    id: '7',
    imgSrc: 'partnership/grow/ripplr.png'
  },
  {
    id: '8',
    imgSrc: 'partnership/grow/webengage.png'
  },
  {
    id: '9',
    imgSrc: 'partnership/grow/clickpost.png'
  },
  {
    id: '10',
    imgSrc: 'partnership/grow/digiDarts.png'
  },
  {
    id: '11',
    imgSrc: 'partnership/grow/imbus.png'
  },
  {
    id: '12',
    imgSrc: 'partnership/grow/oneImpression.png'
  },
  {
    id: '13',
    imgSrc: 'partnership/grow/revexMedia.png'
  },
  {
    id: '14',
    imgSrc: 'partnership/grow/winkl.png'
  },
  {
    id: '14',
    imgSrc: 'partnership/grow/Shipway.png'
  }
  // {
  //   id: '4',
  //   imgSrc: sketchbrahma
  // },
  // {
  //   id: '8',
  //   imgSrc: embed
  // }
];
