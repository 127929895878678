const TeamActivity = [
  { image: 'team-activity/tree-plantation-1.png' },
  { image: 'team-activity/tree-plantation-2.png' },
  { image: 'team-activity/tree-plantation-3.png' },
  { image: 'team-activity/tree-plantation-4.png' },
  { image: 'team-activity/tree-plantation-5.png' },
  { image: 'team-activity/tree-plantation-6.png' }
];

export default TeamActivity;
