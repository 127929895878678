/* eslint-disable import/prefer-default-export */
import nbf from '../../static/partnership/png/nbf.svg';
import banks from '../../static/partnership/png/bank.svg';
import family from '../../static/partnership/png/family.svg';
import wealth from '../../static/partnership/png/private.svg';
import syndicate from '../../static/partnership/png/synticate.svg';

export const benefitsList = [
  {
    id: '1',
    imgSrc: nbf,
    head: 'NBFCs',
    desc: 'Attractive returns with significant risk participation from Klub'
  },

  {
    id: '2',
    imgSrc: banks,
    head: 'Banks',
    desc: 'Explore synergies on both the assets and liabilities sides'
  },

  {
    id: '3',
    imgSrc: family,
    head: 'Family Offices',
    desc: 'Build relationships with best early-stage consumer brands'
  },

  {
    id: '4',
    imgSrc: wealth,
    head: 'Private Wealth Managers',
    desc: 'High liquidity exposure to early-stage consumer startups'
  },

  {
    id: '5',
    imgSrc: syndicate,
    head: 'Syndicates',
    desc: 'Invest along with your network and get rewarded'
  }
];
